<template>
  <div>
    <v-dialog
      v-if="selected_image"
      v-model="showImageDialog"
      scrollable
      width="auto"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Image</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img style="max-width: 100%;" :src="selected_image" alt="" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeImageDialog">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card elevation="2" outlined shaped tile>
      <v-card-title>Dominio</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        v-if="selected"
        persistent
        scrollable
        v-model="showEdit"
        max-width="600px"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="`nombre*`"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <picture-input
                      :crop="false"
                      class="my-picture-input"
                      ref="pictureInput"
                      width="500"
                      height="300"
                      size="5"
                      :prefill="
                        getImageUri(selected.static ? selected.static : null)
                      "
                      accept="image/jpeg,image/png"
                      button-class="btn btn-outline-info"
                      aspect="Landscape"
                      :custom-strings="{
                        upload: this.$t('uploading_image'),
                        change: this.$t('change'),
                        drag: `Logo*`
                      }"
                      @change="onPicture"
                    >
                    </picture-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="isSaving"
                @click="onSaveClick(selected)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{
            $t("salesforce.user_confirmdelete")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="!isManager"
              color="primary"
              dark
              class="mb-2"
              @click="onItemEdit"
            >
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="domains_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="domains"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="`700px`"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="total_domains"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";
import PictureInput from "vue-picture-input";

export default {
  name: "Domains",
  components: {
    PictureInput
  },
  data: function() {
    return {
      total_domains: 0,
      domains: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: ["name"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (rowIndex % 2 !== 0) {
            return "table-body-cell-even";
          } else {
            return "table-body-cell-odd";
          }
        }
      },
      filterCriteria: {
        name: {}
      },
      showEmpty: false,

      selected_image: null,
      showImageDialog: false,

      loading: true,
      options: {},
      editedIndex: -1,
      selected: {},
      valid: true,
      requiredRules: [v => !!v || "Required"],
      showEdit: false,
      dialogDelete: false,
      deleteItemId: -1,

      search_projects: null,
      isProjectsSearching: false,
      filtered_projects: [],
      isSaving: false,

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple"
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async search(val) {
      await this.searchParent(val);
    },
    async search_projects(val) {
      this.search_brands(val);
    }
  },
  computed: {
    ...mapGetters("auth", ["isManager", "user"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      let common_cols = [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 25,
          sortBy: "ASC",
          fixed: "left"
        },
        {
          key: "name",
          title: this.$t("salesforce.name"),
          field: "name",
          align: "left",
          width: 50,
          sortBy: ""
        },
        {
          key: "logo",
          title: "Logo",
          field: "logo",
          align: "left",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row?.static !== null) {
              let thumbnail_url = this.getImageUri(row.static);
              return (
                <img
                  style="border: 1px solid #aaa; cursor: pointer; max-width: 100%; object-fit: cover; max-height: 70px"
                  src={thumbnail_url}
                  alt=""
                  on-click={() => this.onItemShowImageDialog(row.static)}
                />
              );
            } else {
              return <span>&nbsp;</span>;
            }
          }
        },
        {
          key: "q",
          title: "Editar",
          field: "actions",
          align: "center",
          width: 35,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="red"
                  class="mr-3"
                  on-click={() => this.onItemRemove(row)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        }
      ];
      return [...common_cols];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    getQueryParams(options) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.loading = true;
      this.showloadingspinner();
      let query_params_string = this.getQueryParams(this.options);
      let fetch_url = "domain";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.domains = resp.data;
      this.total_domains = resp.total;
      this.loading = false;
      this.closeloadingspinner();
    },

    getThumbnailUri(file) {
      return ApiService.getThumbnailUri(file);
    },
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    onPicture(img) {
      this.selected.materialPhoto.img = this.$refs["pictureInput"].file;
    },

    onItemShowImageDialog(image) {
      this.selected_image = this.getImageUri(image ? image : null);
      this.showImageDialog = true;
    },
    closeImageDialog() {
      this.showImageDialog = false;
      this.$nextTick(() => {
        this.selected = null;
        this.selected_image = null;
        this.editedIndex = -1;
      });
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        logo: _.get(item, "logo"),
        static: _.get(item, "static")
      };
      return { ...model };
    },

    async onSaveClick(item) {
      this.selected.img = this.$refs["pictureInput"].file;
      if (this.$refs.form.validate()) {
        if (!this.selected.img) {
          logError("Cargue la imagen del logotipo.");
          return;
        }
        this.isSaving = true;
        const body = _.omit(item);
        let img = item.img;
        if (img && !img.name.includes("?_=")) {
          let { data } = await ApiService.uploadImage(img);
          logInfo("imagen subida");
          body.logo = data.id;
        }
        let save_res = null;
        if (body.id) {
          save_res = await ApiService.put(`domain/update/${body.id}`, body);
        } else {
          save_res = await ApiService.post(`domain/create`, body);
        }
        if (save_res.success) {
          logInfo("Usuario actualizada");
          this.showEdit = false;
          this.getDataFromApi();
        } else {
          logError(save_res.error);
        }
        this.isSaving = false;
      }
    },

    close() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId && this.deleteItemId.id > 0) {
        this.closeDelete();
        ApiService.delete(`domain/${this.deleteItemId.id}`).then(() => {
          logInfo("Usuario eliminada");
          this.getDataFromApi();
        });
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    showloadingspinner() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#domains_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    closeloadingspinner() {
      this.loadingInstance.close();
    },

    init() {
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style>
.v-btn {
  text-transform: capitalize;
}
table tr td .v-btn {
  margin-bottom: 0 !important;
}
.filter_row td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
td.ve-table-body-td.table-body-cell-even {
  background: #ddd !important;
}
td[col-key="logo"].ve-table-body-td {
  text-align: center !important;
}
</style>
